import React, {
  useRef,
  useState,
} from 'react';
import {
  withApi,
  withNotification,
} from 'wrappers';
import {
  messages,
  masterMessages,
} from 'constantes';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  ButtonSave,
  ButtonCancelar,
} from 'components/Controls';
import PropTypes from 'prop-types';
import Table from 'components/Table/Table';
import endPoints from 'endPoints/endPoints';
import LabelLink from 'components/labelLink/labelLink';
import ConfirmModal from 'components/dialogs/ConfirmModal';
import DefaultActions from 'components/Table/DefaultActions';

const labelLinkGasto = {
  gasto: '/app/configuracion/tesoreria/gastos/edit',
  gastoPermissions: 'app:general:configuracion:tesoreria:gastos:edit',
};

const GastoGenericTab = ({
  entidadId,
  abreviatura,
  doPost,
  doDelete,
  appSuccess,
  appWarning,
  edit = true,
  editable = true,
  genericException,
  permissions = {},
}) => {
  const child = useRef();

  const [openModal, setOpenModal] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const [selected, setSelected] = useState([]);

  const endPoint = endPoints.scm.compra.maestros.gastoEntidad;
  const m = masterMessages.scm.compra.maestros.gastoEntidad;

  const handleModalCreate = () => {
    setOpenModal(!openModal);
    setSelected([]);
  };
  const handleModalDelete = ({ id = null } = {}) => setIdDelete(id);

  const columns = [
    {
      name: 'gasto',
      label: 'Gasto',
      type: 'autocomplete',
      extra: {
        url: endPoints.scm.compra.maestros.gastos.autocomplete,
        requestParams: { filter: true },
      },
      component: row => (
        <LabelLink
          labelName={row.gasto}
          labelUrl={`${labelLinkGasto.gasto}/${row.gastoId}`}
          targetType="_blank"
          permission={[labelLinkGasto.gastoPermissions]}
        />
      ),
    },
    {
      name: 'clasificacion',
      label: 'Clasificación gasto',
      type: 'autocomplete',
      extra: {
        url: endPoints.scm.compra.maestros.clasificacionGasto.autocomplete,
      },
    },
    {
      name: 'tipo',
      label: 'Tipo',
    },
    {
      name: 'cuentaContable',
      label: 'cuenta',
      type: 'autocomplete',
      extra: {
        url: endPoints.contabilidad.maestros.cuentasContables.autoComplete,
        requestParams: { withName: true },
      },
    },
    {
      name: 'afecta_factura_cliente',
      label: 'Afecta factura cliente',
      type: 'check',
      filterMessage: "Si desea buscar por datos aprobados, dar clic " +
      "para activar, para datos cancelados dar clic para activar y de nuevo para desactivar",
    },
    {
      name: 'acciones',
      label: 'Acciones',
      filter: false,
      component: row => (
        <DefaultActions
          row={row}
          onDelete={handleModalDelete}
          disabledUpdate={!editable || !edit}
          disabledDelete={!editable || !edit}
          deletePermissions={permissions.deletePermissions}
        />
      ),
    },
  ];

  const columnsModal = [
    {
      name: 'gasto',
      label: 'Gasto',
      type: 'autocomplete',
      extra: {
        url: endPoints.scm.compra.maestros.gastos.autocomplete,
        requestParams: { filter: true },
      },
    },
    {
      name: 'clasificacionGasto',
      label: 'Clasificación gasto',
      type: 'autocomplete',
      extra: {
        url: endPoints.scm.compra.maestros.clasificacionGasto.autocomplete,
      },
    },
    {
      name: 'tipo',
      label: 'tipo',
    },
    {
      name: 'cuenta',
      label: 'cuenta',
      type: 'autocomplete',
      extra: {
        url: endPoints.contabilidad.maestros.cuentasContables.autoComplete,
        requestParams: { withName: true },
      },
    },
  ];

  const onRowsSelect = (rows) => {
    const data = [];
    rows.map(item => data.push({
      gastoId: item.id,
    }));

    setSelected(data);
  };

  const deleteGasto = async () => {
    try {
      await doDelete({
        url: `${endPoint.base}/${idDelete}`,
      });
      appWarning(messages.crud.delete);
      child.current.refresh();
      handleModalDelete();
    } catch (e) {
      genericException(e);
    }
  };

  const onSubmit = async () => {
    try {
      await doPost({
        url: endPoint.base,
        data: {
          entidadId,
          abreviatura,
          gastos: selected,
        },
      });
      appSuccess(messages.crud.new);
      handleModalCreate();
      child.current.refresh();
    } catch (e) {
      genericException(e);
    }
  };

  return (
    <>
      <ConfirmModal
        open={Boolean(idDelete)}
        onClose={handleModalDelete}
        title={m.deleteTitle}
        message={m.deleteMessage}
        onAccept={deleteGasto}
      />
      <Dialog
        open={openModal}
        onClose={handleModalCreate}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {m.createTitle}
        </DialogTitle>
        <DialogContent>
          <Table
            serverSideUrl={endPoint.getGastosNotInEntidad}
            serverSideData={{
              entidadId,
              abreviatura,
            }}
            columns={columnsModal}
            onRowsSelect={onRowsSelect}
            genericSearch={false}
          />
        </DialogContent>
        <DialogActions>
          <ButtonCancelar onClick={handleModalCreate} />
          <ButtonSave
            label="Guardar"
            disabled={selected.length === 0}
            onClick={onSubmit}
          />
        </DialogActions>
      </Dialog>
      <Table
        forwardedRef={child}
        serverSideUrl={endPoint.base}
        serverSideData={{
          entidadId,
          abreviatura,
        }}
        columns={columns}
        onCreate={handleModalCreate}
        disableCreateButton={!editable || !edit}
        createPermissions={permissions.createPermissions}
      />
    </>
  );
};

GastoGenericTab.propTypes = {
  entidadId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  editable: PropTypes.bool,
  abreviatura: PropTypes.string.isRequired,
  doPost: PropTypes.func,
  doDelete: PropTypes.func,
  appSuccess: PropTypes.func,
  appWarning: PropTypes.func,
  genericException: PropTypes.func,
  edit: PropTypes.bool,
  permissions: PropTypes.oneOfType([PropTypes.object])
};

export default withApi(withNotification(GastoGenericTab));
